<template>
    <div>
      <el-button type="success" @click="showAddTourIdeaModal"
        round>Add A Destination</el-button>
      <article>
        <section>
          <div class="category_dialogs">
            <el-dialog :title="`Add A Destination`" :visible.sync="addDestinationModal" :destroy-on-close="true"
              :before-close="handleClose" style="text-align: left">
              <div v-loading="submitting">
                <el-form :model="destinationForm" :rules="rules" label-position="top" ref="destinationForm"
                  class="demo-destinationForm2">
  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="Title" prop="title">
                        <el-input v-model="destinationForm.title"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="description" prop="description">
                        <el-input textarea v-model="destinationForm.description"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="Destination Photo">
                        <el-upload class="upload-demo" drag action="''" :on-change="handleTourIdeaPhotoPreview"
                          :on-remove="handleTourIdeaPhotoRemove" :file-list="categoryPhotoFileLists" :auto-upload="false"
                          :multiple="false">
                          <div v-if="!isUploadingFile">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              Drop file here or <em>click to upload</em>
                            </div>
                          </div>
                          <div v-else>
                            <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                              :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                            <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                              :stroke-width="4"></el-progress>
                            <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                              {{
                                imageUploadingProgress == 100
                                ? "Uploaded"
                                : "Uploading..."
                              }}
                            </div>
                          </div>
                          <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                        </el-upload>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button type="success" round @click="addDestination('destinationForm')">Add Destination</el-button>
                  </el-form-item>
                </el-form>
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Bakyala Safaris, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co"
                        style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        selectedDestinationPhotoFile: null,
        isUploadingFile: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        categoryPhotoFileLists: [],
        addDestinationModal: false,
        submitting: false,
        destinationForm: {
          title: "",
          description: "",
        },
        rules: {
          title: [
            {
              required: true,
              message: "Title is required",
              trigger: "blur",
            },
          ],
          description: [
            {
              required: true,
              message: "Description is required",
              trigger: "blur",
            },
          ],
          
        },
      };
    },
    methods: {
      handleClose(done) {
        done();
      },
      handleTourIdeaPhotoPreview(file) {
        console.log(file.raw);
        this.categoryPhotoFileLists = [file];
        this.selectedDestinationPhotoFile = file.raw;
      },
      handleTourIdeaPhotoRemove() {
        this.selectedDestinationPhotoFile = null;
      },
  
      showAddTourIdeaModal() {
        this.addDestinationModal = true;
        this.destinationForm.title = "";
      },
  
      async addDestination(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("title", this.destinationForm.title);
              formData.append("description", this.destinationForm.description);
              formData.append("image", this.selectedDestinationPhotoFile);
              formData.append("countryID", this.$route.params.countryID);
              formData.append("createdBy", this.$store.state.userId);
              let response = await this.$http.post(`destinations`, formData, {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              });
              if (
                response.data.success &&
                response.data.message == "DESTINATION_CREATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Destination added successfully",
                  type: "success",
                });
                this.addDestinationModal = false;
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to add a day",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  
  <style>
  .category_dialogs .el-dialog {
    width: 40%;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .category_dialogs .el-dialog {
      width: 50%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .category_dialogs .el-dialog {
      width: 60%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .category_dialogs .el-dialog {
      width: 80%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .category_dialogs .el-dialog {
      width: 80%;
    }
  }
  </style>
  